import React from 'react'
import PropTypes from "prop-types";
import {Link} from 'gatsby';

const ButtonTemplate = ({template, classes}) => (

    <div className={classes}>
        {template}
    </div>
);

const Button = ({children, link, title, click, type = 'standard'}) => {
    const classes = type === 'standard' ?
        'c-button' : `c-button c-button--${type}`;

    const wrapClasses = type === 'standard' ?
        'c-button-wrap' : `c-button-wrap c-button-wrap--${type}`;

    const getLink = () => (
        <Link to={link} className={classes} title={title}>
            {children}
        </Link>
    );

    const getJsBtn = () => (
        <button className={classes} title={title} onClick={click}>
            {children}
        </button>
    )

    const template = click || type === 'form' ? getJsBtn() : getLink();

    return (<ButtonTemplate template={template} classes={wrapClasses}></ButtonTemplate>);
}

Button.propTypes = {
    children: PropTypes.any,
    link: PropTypes.string,
    title: PropTypes.string,
    click: PropTypes.func,
    type: PropTypes.string
}

export default Button
