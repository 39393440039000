import { createGlobalState } from 'react-hooks-global-state';

const initialState = {
    section: 0,
    navOpen:false,
    navVisible: true
};

const { useGlobalState } = createGlobalState(initialState);

export {useGlobalState}
